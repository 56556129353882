<template>
    <div class="home">
      
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/label/topiclist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
      <div class="pstion">
          <div style="display: flex;">编号:{{form.id}}<div style="margin-left:auto">{{form.status==1?'启用':'禁言'}}</div></div>
          <div>生成时间:{{form.created_at}}</div>
        </div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
        
          <el-form-item :required="true" label="一级分类名称">
            <el-input  v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="一级分类英文名称">
            <el-input v-model="form.en_name"></el-input>
          </el-form-item>
        <el-form-item label="排序">
            <el-input v-model="form.sort"></el-input>
          </el-form-item>
          <el-form-item :required="false" label="是否在前端展示">
            <el-checkbox v-model="form.is_show"></el-checkbox>
          </el-form-item>
          <el-form-item :required="false" label="上传图片">
            <el-upload
              class="avatar-uploader"
              action="#"
                :http-request="httpRequest"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          <div class="redtitle">请上传尺寸为240*162的JPG、png图片，大小在150kb内</div>
          </el-form-item>
          <el-form-item label="二级分类">
            <div class="flex" v-for="(item, index) in form.second"
          :key="index">
            <el-input v-model="item.name"></el-input>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteData(item,index)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              v-if="form.second.length - 1 === index"
              @click="add"
            ></el-button>
          </div>
          </el-form-item>
      <el-form-item>
        <el-button type="primary"  v-if="!look" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      imageUrl: '',
      form: {
          second: [],
        },
        options:[],
        look:false,
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
    handleAvatarSuccess(res, file) {
      console.log(res )
        this.imageUrl = URL.createObjectURL(file.raw);
      },httpRequest(data) {
            let file = data.file;
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
                 return 
            }
          let _this = this;
        let formdata = new FormData();
        formdata.append("file",file,file.name)
          axios.post(config.homeMenuUpload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
             if(response.data.code == 200){
              const newImg = new Image();
              newImg.src = response.data.data[0].images;
              newImg.onload = () => {
                if(newImg.width != 240 || newImg.height != 162){
                  _this.$message.error('图片的尺寸必须为240*162!');
                  return
                }
              _this.form.images = response.data.data[0].images;
              _this.imageUrl = response.data.data[0].images;
              _this.$message({
                message: '成功',
                type: 'success'
              });
              };
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        },
      beforeAvatarUpload() {//file
        // const isJPG = file.type === 'image/jpeg';
        // const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        // if (!isLt2M) {
        //   this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        // return isJPG && isLt2M;
        return true
      },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      let arr = [];
      let length = postdata.second.length;
      for(let i in postdata.second){
          arr.push(postdata.second[i].name);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        this.$alert("名称重复");
        return;
      }
      postdata.c_name = postdata.second;
      let obj = {
          id:postdata.id,
          sort:postdata.sort,
          c_name:postdata.c_name,
          f_name:postdata.name,
          en_f_name:postdata.en_name,
          updated_at:postdata.updated_at,
          is_show:postdata.is_show?1:0,
          images:postdata.images,
      }
      console.log(obj)
       if(!obj.f_name || !obj.en_f_name){
        _this.$message.error("名称必填");
        return;
      }
       if(!postdata.sort){
        _this.$message.error("排序必填");
        return;
      }
      for(let i in postdata.c_name){
        if(!postdata.c_name[i].name){
          _this.$message.error("二级分类名不能为空");
          return;
        }
          arr.push(postdata.c_name[i].name);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        _this.$message.error("标签名重复");
        return;
      }
      axios.post(config.topicCategory,obj)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/label/topiclist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        axios.get(config.topicCategoryInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.imageUrl = response.data.data.images;
                _this.form.is_show = response.data.data.is_show?true:false;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },// 增加
    add() {
        this.form.second.push({
          id: 0,
          name: ""
        });
    },
    // 删除 
    deleteData(item,index) {
      if(item.second_topic_count > 0){
        this.$alert("该分类正在被使用");
        return;
      }
      if (this.form.second.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.second.splice(index, 1);
      }
    },
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>

.pstion{
  position: absolute;
  right: 100px;
  text-align: left;
}
.form{
    width: 500px;
    margin-left: 200px;
}
.flex{
    display: flex;
}
.el-icon-close:before{
  font-size: 24px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  
  .redtitle{
    color: red;
    line-height: 20px;
    font-size: 16px;
  }
</style>
